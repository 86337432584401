html {
  scroll-behavior: smooth;
}

.frame-parent, .how-it-works, .header-parent, .features, .testimonials, .coming-soon, .bg-image-1, .bg-image-2, .faq {
  position: relative;
}

.header-parent {
  z-index: 2;
}

.bg-video-wrapper {
  z-index: 1;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.navigation-link-wrapper {
  justify-content: space-between;
  align-items: center;
  gap: var(--xl1);
  flex-direction: row;
  display: flex;
}

.navigation-link {
  cursor: pointer;
  padding: 0 0 5px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.navigation-link:before {
  content: "";
  background-color: var(--text-brand-primary-brand-primary);
  visibility: hidden;
  width: 0;
  height: 1px;
  transition: all .3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
}

.navigation-link:hover:before {
  visibility: visible;
  width: 100%;
}

.play-video {
  border: 10px solid;
  border-color: var(--background-overlay-white-light);
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

.button13, .button12 {
  z-index: 10;
  position: relative;
}

.footer-bottom-highlight {
  position: relative;
  overflow: hidden;
}

.footer-bottom-highlight:before {
  content: "";
  z-index: 3;
  background: radial-gradient(50% 50%, #005475 0%, #05140a00 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: -80%;
  left: 0;
}

.footer3 {
  padding-bottom: 10px;
}

.homepage {
  padding-bottom: 0;
}

.footer {
  padding-bottom: var(--padding-13xl);
}

.expanded {
  display: block;
}

.collapsed {
  display: none;
}

.subtract-icon {
  cursor: pointer;
}

.demoBtn {
  font-weight: bold;
  text-decoration: none;
}

.arrow-buttons-icon {
  cursor: pointer;
  transition: opacity .3s ease-in-out;
}

.arrow-buttons-icon.hidden {
  opacity: 0;
}

.slider {
  width: 100%;
  height: 40rem;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.slide {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  transition: transform 1s;
  display: flex;
  position: absolute;
  top: 0;
}

.slider__btn {
  z-index: 10;
  color: var(--text-neutral-primary-inverse);
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-radius: 50%;
  width: 5.5rem;
  height: 5.5rem;
  font-family: inherit;
  position: absolute;
  top: 50%;
}

.slider__btn svg {
  fill: var(--text-brand-primary-brand-primary);
}

.slider__btn--left {
  left: 20%;
  transform: translate(-50%, -50%);
}

.slider__btn--right {
  right: 20%;
  transform: translate(50%, -50%);
}

.slider-mobile {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50rem;
  margin: 0 auto;
  display: flex;
  position: relative;
  overflow: hidden;
}

.slide-mobile {
  justify-content: center;
  align-items: center;
  height: auto;
  transition: transform 1s;
  display: flex;
  position: absolute;
  top: 0;
}

.slider-mobile .testimonial p {
  width: auto;
}

.testimonial {
  text-align: center;
  width: 50%;
  position: relative;
}

.testimonial p {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.frame-child-testimonials {
  margin: 40px auto;
}

.noStyleBtn {
  background-color: #0000;
  border: none;
}

.faq-icon-collapsed {
  background-image: url("add.40cabdcb.svg");
}

.faq-icon-expanded {
  background-image: url("subtract.24e264cd.svg");
}

.faq-icon {
  background-size: cover;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.hover-arrow-class {
  background-image: url("arrow-buttons@2x-hover.39128257.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 50px;
  height: 50px;
  display: inline-block;
}

.default-arrow-class {
  background-image: url("arrow-buttons@2x.38a0d16f.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 50px;
  height: 50px;
  display: inline-block;
}

.email-input-custom {
  flex: 1;
  width: 100%;
  position: relative;
}

.arrow-buttons-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.subscribe-email {
  border: none;
  border-bottom: 1px solid var(--text-neutral-quaternary-inverse);
  color: var(--text-neutral-primary-inverse);
  font-size: 16px;
  font-family: var(--heading-heading-xs);
  background-color: #0000;
  outline: none;
  width: 100%;
  height: 50px;
}

.unlock-your-longevity1 {
  font-size: var(--heading-heading-l-size);
  line-height: 40px;
}

.by-subscribing-you {
  color: var(--text-neutral-tertiary-inverse);
}

.privacy-policy {
  color: var(--text-brand-primary-brand-primary);
}

.by-subscribing-you-container {
  font-size: var(--text-text-m-regular-size);
  color: var(--text-neutral-primary-inverse);
  line-height: 20px;
  position: relative;
}

a.privacy-policy {
  text-decoration: none;
}

.newsletter1-custom {
  flex-direction: column;
}

.newsletter-child {
  justify-content: space-between;
  align-items: flex-end;
  gap: 20px;
  width: 100%;
  display: flex;
}

.copyright-2024-custom {
  text-align: center;
  z-index: 1000;
  position: relative;
}

.privacy-policy-footer {
  color: inherit;
  text-decoration: none;
}

.frame-parent:after {
  content: "";
  z-index: 5;
  background-image: url("dna_glow_1.58a5b9d7.png");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 400px;
  height: 685px;
  position: absolute;
  bottom: 40%;
  right: -40%;
  transform: rotate(40deg);
}

.frame-parent:before {
  content: "";
  z-index: 5;
  background-image: url("dna_glow_1.58a5b9d7.png");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
  width: 1649px;
  height: 948px;
  position: absolute;
  bottom: -4%;
  left: -50%;
  transform: rotate(-36deg);
}

.how-it-works:after {
  content: "";
  z-index: 5;
  background-image: url("dna_glow_2.eba786f9.png");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 300px;
  height: 827px;
  position: absolute;
  bottom: -20%;
  right: -70%;
  transform: rotate(150deg)scale(-1, 1);
}

.features:after {
  content: "";
  z-index: 5;
  background-image: url("dna_glow_2.eba786f9.png");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 370px;
  height: 885px;
  position: absolute;
  bottom: -100%;
  left: -30%;
  transform: rotate(-30deg);
}

.features:before {
  content: "";
  z-index: 5;
  background-image: url("dna_glow_1.58a5b9d7.png");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 450px;
  height: 885px;
  position: absolute;
  bottom: -200%;
  right: -40%;
  transform: rotate(45deg);
}

.coming-soon:after {
  content: "";
  z-index: 5;
  background-image: url("dna_glow_1.58a5b9d7.png");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 450px;
  height: 885px;
  position: absolute;
  top: 0%;
  left: -40%;
  transform: rotate(-145deg);
}

.coming-soon:before {
  content: "";
  z-index: 5;
  background-image: url("dna_glow_2.eba786f9.png");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 450px;
  height: 885px;
  position: absolute;
  top: 25%;
  right: -35%;
  transform: rotate(145deg);
}

.bg-image-1:after {
  content: "";
  z-index: 5;
  background-image: url("dna_glow_2.eba786f9.png");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 450px;
  height: 885px;
  position: absolute;
  top: -119%;
  left: -35%;
  transform: rotate(-45deg);
}

.bg-image-2:after {
  content: "";
  z-index: 5;
  background-image: url("dna_glow_2.eba786f9.png");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 450px;
  height: 885px;
  position: absolute;
  top: 0%;
  right: -40%;
  transform: rotate(-225deg);
}

.faq:after {
  content: "";
  z-index: 5;
  background-image: url("dna_glow_2.eba786f9.png");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 450px;
  height: 885px;
  position: absolute;
  top: 0%;
  left: -70%;
  transform: rotate(213deg);
}

#toast {
  z-index: 100;
  text-align: center;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  min-width: 250px;
  padding: 15px;
  font-size: 20px;
  display: none;
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.success-toast {
  background-color: var(--color-mediumseagreen);
}

.error-toast {
  background-color: #f33;
}

.info-toast {
  background-color: #5bc0de;
}

input:-webkit-autofill {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

.loader {
  border: 5px solid var(--color-mediumseagreen);
  box-sizing: border-box;
  border-bottom-color: #0000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: 1s linear infinite rotation;
  display: none;
  position: absolute;
  bottom: 20px;
  right: 10px;
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (width <= 1200px), (width >= 1921px) {
  .frame-parent:after, .frame-parent:before, .how-it-works:after, .features:after, .features:before, .coming-soon:after, .coming-soon:before, .bg-image-1:after, .bg-image-2:after, .faq:after {
    display: none;
  }
}

@media screen and (width <= 960px) {
  .header .logo-parent div, .header .logo-parent a {
    display: none;
  }

  .video-1-parent {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    display: flex;
  }
}

@media screen and (width <= 480px) {
  .video-1-parent-mobile {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .expanded, .collapsed {
    width: 100%;
    height: auto;
  }

  .how-it-works-child {
    left: 0;
  }

  .copyright-2024-custom {
    margin-bottom: 15px;
  }
}

.homepage.light-mode, .homepage-mobile.light-mode {
  background-color: var(--text-neutral-primary-inverse);
}

.navigation-link.light-mode {
  color: var(--background-neutral-primary);
}

.appBtn.light-mode {
  background-color: var(--text-brand-primary-brand-primary);
  border-radius: var(--m1);
}

.appBtn.light-mode b {
  color: var(--background-neutral-secondary);
}

.appBtn.light-mode:hover {
  background-color: var(--color-mediumseagreen);
}

.unlock-your-longevity.light-mode, .unlock-your-longevity1.light-mode {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #109e5e 0%, #007cad 100%) text;
}

.content-text.light-mode {
  color: var(--text-neutral-tertiary);
}

.content-text-background.light-mode {
  background: linear-gradient(90deg, #109e5e 0%, #007cad 100%);
  background: var(--text-neutral-tertiary);
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.white-text.light-mode {
  color: var(--text-neutral-primary-inverse);
  -webkit-text-fill-color: inherit;
  background: none;
}

.whiteBtn.light-mode {
  background-color: var(--text-neutral-primary-inverse);
  color: var(--text-neutral-tertiary);
  border: none;
  border-radius: 8px;
}

.whiteBtn.light-mode b {
  color: var(--text-neutral-tertiary);
}

.whiteBtn.light-mode:hover > div {
  border-radius: inherit;
  border: none;
}

.banners.light-mode > div:first-child {
  background: linear-gradient(360deg, #38eb9a 0%, #0b6b3f 100%);
  border: none;
}

.banners.light-mode > div:nth-child(2) {
  background: linear-gradient(0deg, #20beff 0%, #005475 100%);
  border: none;
}

.banners1.light-mode > div:first-child {
  background: linear-gradient(360deg, #38eb9a 0%, #0b6b3f 100%);
  border: none;
}

.banners1.light-mode > div:nth-child(2) {
  background: linear-gradient(0deg, #20beff 0%, #005475 100%);
  border: none;
}

.features-card-alt.light-mode, .features-card-alt4.light-mode {
  background-color: var(--text-neutral-primary-inverse);
}

.community.light-mode {
  background-image: url("community-light.7bb7e525.png"), linear-gradient(#0b6b3f 0%, #38eb9a 100%);
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.newsletter.light-mode, .newsletter1.light-mode {
  background-image: url("newsletter-light.dca424e0.png"), linear-gradient(360deg, #20beff 0%, #005475 100%);
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.subscribe-email.light-mode {
  border-bottom-color: var(--text-neutral-primary-inverse);
}

.faq1.light-mode, .faq6.light-mode {
  background-color: var(--text-neutral-primary-inverse);
}

.footer2.light-mode, .footer4.light-mode {
  background-color: var(--text-neutral-secondary-inverse);
}

.light-green-text.light-mode {
  color: var(--color-mediumseagreen);
}

.footer-bottom-highlight.light-mode:before {
  content: "";
  z-index: 3;
  background: radial-gradient(50% 50%, #005475 0%, #05140a00 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: -87%;
  left: 0;
}

.subscribe-email.light-mode {
  color: var(--text-neutral-tertiary) !important;
}

.mode-icon {
  cursor: pointer;
  background-image: url("dark-mode-moon.841099dd.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
}

.mode-icon.light-mode {
  cursor: pointer;
  background-image: url("light-mode-sun.7ec9040a.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
}

.logo-icon {
  cursor: pointer;
  background-image: url("logo.351a5c71.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 177px;
  height: 32px;
}

.logo-icon.light-mode {
  cursor: pointer;
  background-image: url("logo-dark.2b8bc6ad.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 177px;
  height: 32px;
}

.twitter-icon {
  cursor: pointer;
  background-image: url("vector.db410468.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 14px;
  height: 14px;
}

.twitter-icon.light-mode {
  cursor: pointer;
  background-image: url("twitter-dark.b1bdd68e.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 14px;
  height: 14px;
}

.instagram-icon {
  cursor: pointer;
  background-image: url("instagram.cb8a52bc.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 14px;
  height: 14px;
}

.instagram-icon.light-mode {
  cursor: pointer;
  background-image: url("instagram-dark.cf13027b.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 14px;
  height: 14px;
}

.linkedin-icon {
  cursor: pointer;
  background-image: url("linkedin.4ffad73d.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 14px;
  height: 14px;
}

.linkedin-icon.light-mode {
  cursor: pointer;
  background-image: url("linkedin-dark.df09be89.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 14px;
  height: 14px;
}

.facebook-icon {
  cursor: pointer;
  background-image: url("facebook.d05c1f94.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 14px;
  height: 14px;
}

.facebook-icon.light-mode {
  cursor: pointer;
  background-image: url("facebook-dark.804a6b0c.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 14px;
  height: 14px;
}
/*# sourceMappingURL=index.606ce168.css.map */
