html {
  scroll-behavior: smooth;
}

.frame-parent,
.how-it-works,
.header-parent,
.features,
.testimonials,
.coming-soon,
.bg-image-1,
.bg-image-2,
.faq {
  position: relative;
}

.header-parent {
  z-index: 2;
}

.bg-video-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1;
}

.navigation-link-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--xl1);
}

.navigation-link {
  font-weight: bold;
  text-decoration: none;
  position: relative;
  display: inline-block;
  padding: 0px 0px 5px 0px;
  cursor: pointer;
}

.navigation-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: var(--text-brand-primary-brand-primary);
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.navigation-link:hover::before {
  visibility: visible;
  width: 100%;
}

.play-video {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border: 10px solid;
  border-color: var(--background-overlay-white-light);
}

.button13,
.button12 {
  position: relative;
  z-index: 10;
}

.footer-bottom-highlight {
  position: relative;
  overflow: hidden;
}

.footer-bottom-highlight::before {
  content: "";
  position: absolute;
  bottom: -80%;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #005475 0%,
    rgba(5, 20, 10, 0) 100%
  );
  z-index: 3;
}

.footer3 {
  padding-bottom: 10px;
}

.homepage {
  padding-bottom: 0;
}

.footer {
  padding-bottom: var(--padding-13xl);
}

.expanded {
  display: block;
}

.collapsed {
  display: none;
}

.subtract-icon {
  cursor: pointer;
}

.demoBtn {
  font-weight: bold;
  text-decoration: none;
}

.arrow-buttons-icon {
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.arrow-buttons-icon.hidden {
  opacity: 0;
}

/* TESTIMONIALS */
.slider {
  /* max-width: 100rem; */
  height: 40rem;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.slide {
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 1s;
}

.slider__btn {
  position: absolute;
  top: 50%;
  z-index: 10;
  border: none;
  /* background: var(--text-brand-primary-brand-primary); */
  background-color: transparent;
  font-family: inherit;
  color: var(--text-neutral-primary-inverse);
  border-radius: 50%;
  height: 5.5rem;
  width: 5.5rem;
  cursor: pointer;
}

.slider__btn svg {
  fill: var(--text-brand-primary-brand-primary);
}

.slider__btn--left {
  left: 20%;
  transform: translate(-50%, -50%);
}

.slider__btn--right {
  right: 20%;
  transform: translate(50%, -50%);
}

/* TESTIMONIALS MOBILE */
.slider-mobile {
  height: 50rem;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide-mobile {
  position: absolute;
  top: 0;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 1s;
}

.slider-mobile .testimonial p {
  width: auto;
}

.testimonial {
  width: 50%;
  position: relative;
  text-align: center;
}

.testimonial p {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
}

.frame-child-testimonials {
  margin: 40px auto;
}

.noStyleBtn {
  background-color: transparent;
  border: none;
}

.faq-icon-collapsed {
  background-image: url(./public/add.svg);
}

.faq-icon-expanded {
  background-image: url(./public/subtract.svg);
}

.faq-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: cover;
}

.hover-arrow-class {
  background-image: url(./public/arrow-buttons@2x-hover.png);
  width: 50px;
  height: 50px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
}

.default-arrow-class {
  background-image: url(./public/arrow-buttons@2x.png);
  width: 50px;
  height: 50px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
}


.email-input-custom {
  position: relative;
  flex: 1;
  width: 100%;
}

.arrow-buttons-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.subscribe-email {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--text-neutral-quaternary-inverse);
  width: 100%;
  outline: none;
  color: var(--text-neutral-primary-inverse);
  font-size: 16px;
  height: 50px;
  font-family: var(--heading-heading-xs);
}

.unlock-your-longevity1 {
  font-size: var(--heading-heading-l-size);
  line-height: 40px;
}

.by-subscribing-you {
  color: var(--text-neutral-tertiary-inverse);
}
.privacy-policy {
  color: var(--text-brand-primary-brand-primary);
}
.by-subscribing-you-container {
  position: relative;
  font-size: var(--text-text-m-regular-size);
  line-height: 20px;
  color: var(--text-neutral-primary-inverse);
}

a.privacy-policy {
  text-decoration: none;
}

.newsletter1-custom {
  flex-direction: column;
}

.newsletter-child {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: flex-end;
  width: 100%;
}

.copyright-2024-custom {
  text-align: center;
  position: relative;
  z-index: 1000;
}

.privacy-policy-footer {
  text-decoration: none;
  color: inherit;
}

/* background images */
.frame-parent::after {
  content: "";
  position: absolute;
  right: -40%;
  bottom: 40%;
  width: 400px;
  height: 685px;
  background-image: url("./public/dna_glow_1.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  transform: rotate(40deg);
  z-index: 5;
}

.frame-parent::before {
  content: "";
  position: absolute;
  left: -50%;
  bottom: -4%;
  width: 1649px;
  height: 948px;
  background-image: url("./public/dna_glow_1.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  transform: rotate(-36deg);
  z-index: 5;
}

.how-it-works::after {
  content: "";
  position: absolute;
  right: -70%;
  bottom: -20%;
  width: 300px;
  height: 827px;
  background-image: url("./public/dna_glow_2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  transform: rotate(150deg) scale(-1, 1);
  z-index: 5;
}

.features::after {
  content: "";
  position: absolute;
  left: -30%;
  bottom: -100%;
  width: 370px;
  height: 885px;
  background-image: url("./public/dna_glow_2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  transform: rotate(-30deg);
  z-index: 5;
}

.features::before {
  content: "";
  position: absolute;
  right: -40%;
  bottom: -200%;
  width: 450px;
  height: 885px;
  background-image: url("./public/dna_glow_1.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  transform: rotate(45deg);
  z-index: 5;
}

.coming-soon::after {
  content: "";
  position: absolute;
  left: -40%;
  top: 0%;
  width: 450px;
  height: 885px;
  background-image: url("./public/dna_glow_1.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  transform: rotate(-145deg);
  z-index: 5;
}

.coming-soon::before {
  content: "";
  position: absolute;
  right: -35%;
  top: 25%;
  width: 450px;
  height: 885px;
  background-image: url("./public/dna_glow_2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  transform: rotate(145deg);
  z-index: 5;
}

.bg-image-1::after {
  content: "";
  position: absolute;
  left: -35%;
  top: -119%;
  width: 450px;
  height: 885px;
  background-image: url("./public/dna_glow_2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  transform: rotate(-45deg);
  z-index: 5;
}

.bg-image-2::after {
  content: "";
  position: absolute;
  right: -40%;
  top: 0%;
  width: 450px;
  height: 885px;
  background-image: url("./public/dna_glow_2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  transform: rotate(-225deg);
  z-index: 5;
}

.faq::after {
  content: "";
  position: absolute;
  left: -70%;
  top: 0%;
  width: 450px;
  height: 885px;
  background-image: url("./public/dna_glow_2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  transform: rotate(213deg);
  z-index: 5;
}

#toast {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 20px;
  min-width: 250px;
  padding: 15px;
  display: none;
}

.success-toast {
  background-color: var(--color-mediumseagreen);
}

.error-toast {
  background-color: #ff3333;
}

.info-toast {
  background-color: #5bc0de;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

.loader {
  width: 40px;
  height: 40px;
  border: 5px solid var(--color-mediumseagreen);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  bottom: 20px;
  right: 10px;
  display: none;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1200px), (min-width: 1921px) {
  .frame-parent::after,
  .frame-parent::before,
  .how-it-works::after,
  .features::after,
  .features::before,
  .coming-soon::after,
  .coming-soon::before,
  .bg-image-1::after,
  .bg-image-2::after,
  .faq::after {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .header .logo-parent div,
  .header .logo-parent a {
    display: none;
  }

  .video-1-parent {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 480px) {
  .video-1-parent-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .expanded,
  .collapsed {
    width: 100%;
    height: auto;
  }

  .how-it-works-child {
    left: 0;
  }

  .copyright-2024-custom {
    margin-bottom: 15px;
  }
}

/* Dark mode / Light mode */
.homepage.light-mode {
  background-color: var(--text-neutral-primary-inverse);
}

.homepage-mobile.light-mode {
  background-color: var(--text-neutral-primary-inverse);
}


.navigation-link.light-mode {
  color: var(--background-neutral-primary);
}

.appBtn.light-mode {
  background-color: var(--text-brand-primary-brand-primary);
  border-radius: var(--m1);
}

.appBtn.light-mode b {
  color: var(--background-neutral-secondary);
}

.appBtn.light-mode:hover {
  background-color: var(--color-mediumseagreen);
}

.unlock-your-longevity.light-mode {
  background: linear-gradient(90deg, #109e5e 0%, #007cad 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.unlock-your-longevity1.light-mode {
  background: linear-gradient(90deg, #109e5e 0%, #007cad 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}


.content-text.light-mode {
  color: var(--text-neutral-tertiary);
}

.content-text-background.light-mode {
  background: linear-gradient(90deg, #109e5e 0%, #007cad 100%);
  background: var(--text-neutral-tertiary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.white-text.light-mode {
  color: var(--text-neutral-primary-inverse);
  background: none;
  -webkit-text-fill-color: inherit;
}

.whiteBtn.light-mode {
  background-color: var(--text-neutral-primary-inverse);
  color: var(--text-neutral-tertiary);
  border-radius: 8px;
  border: none;
}

.whiteBtn.light-mode b {
  color: var(--text-neutral-tertiary);
}

.whiteBtn.light-mode:hover > div {
  border-radius: inherit;
  border: none;
}

.banners.light-mode > div:nth-child(1) {
  background: linear-gradient(360deg, #38eb9a 0%, #0b6b3f 100%);
  border: none;
}

.banners.light-mode > div:nth-child(2) {
  background: linear-gradient(0deg, #20beff 0%, #005475 100%);
  border: none;
}

.banners1.light-mode > div:nth-child(1) {
  background: linear-gradient(360deg, #38eb9a 0%, #0b6b3f 100%);
  border: none;
}

.banners1.light-mode > div:nth-child(2) {
  background: linear-gradient(0deg, #20beff 0%, #005475 100%);
  border: none;
}

.features-card-alt.light-mode {
  background-color: var(--text-neutral-primary-inverse);
}

.features-card-alt4.light-mode {
  background-color: var(--text-neutral-primary-inverse);
}

.community.light-mode {
  background-image: url("./public/community-light.png"),
    linear-gradient(180deg, #0b6b3f 0%, #38eb9a 100%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
}

.newsletter.light-mode,
.newsletter1.light-mode {
  background-image: url("./public/newsletter-light.png"),
    linear-gradient(360deg, #20beff 0%, #005475 100%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
}

.subscribe-email.light-mode {
  border-bottom-color: var(--text-neutral-primary-inverse);
}

.faq1.light-mode {
  background-color: var(--text-neutral-primary-inverse);
}

.faq6.light-mode {
  background-color: var(--text-neutral-primary-inverse);
} 

.footer2.light-mode {
  background-color: var(--text-neutral-secondary-inverse);
}

.footer4.light-mode {
  background-color: var(--text-neutral-secondary-inverse);
}

.light-green-text.light-mode {
  color: var(--color-mediumseagreen)
}

.footer-bottom-highlight.light-mode::before {
  content: "";
  position: absolute;
  bottom: -87%;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #005475 0%,
    rgba(5, 20, 10, 0) 100%
  );
  z-index: 3;
}

.subscribe-email.light-mode {
  color: var(--text-neutral-tertiary) !important;
}

.mode-icon {
  background-image: url(./public/dark-mode-moon.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  width: 30px;
  height: 30px;}

.mode-icon.light-mode {
  background-image: url(./public/light-mode-sun.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.logo-icon {
  background-image: url(./public/logo.svg);
  width: 177px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.logo-icon.light-mode {
  background-image: url(./public/logo-dark.svg);
  width: 177px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.twitter-icon {
  background-image: url(./public/vector.svg);
  width: 14px;
  height: 14px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

}

.twitter-icon.light-mode {
  background-image: url(./public/twitter-dark.svg);
  width: 14px;
  height: 14px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.instagram-icon {
  background-image: url(./public/instagram.svg);
  width: 14px;
  height: 14px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

}

.instagram-icon.light-mode {
  background-image: url(./public/instagram-dark.svg);
  width: 14px;
  height: 14px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.linkedin-icon {
  background-image: url(./public/linkedin.svg);
  width: 14px;
  height: 14px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

}

.linkedin-icon.light-mode {
  background-image: url(./public/linkedin-dark.svg);
  width: 14px;
  height: 14px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.facebook-icon {
  background-image: url(./public/facebook.svg);
  width: 14px;
  height: 14px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

}

.facebook-icon.light-mode {
  background-image: url(./public/facebook-dark.svg);
  width: 14px;
  height: 14px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}


